@import '../../sass/variable';

.h1 {
  text-shadow: 2px 2px 0px  #000, -2px -2px 0px  #000, 2px -2px 0px  #000, -2px 2px 0px  #000;
  color: white;
  font-family: 'Arial';
}
.example {
  font-size: 3em;
  margin: 0;
  -webkit-text-stroke: 0.5px #7F1D36;
}



.col3Padding{
padding-right: 0px !important;
padding-left: 0px !important;
}
  .sub-btn{
    width: auto;
    padding: .94rem;
    background-color: $primary-color;
    color: $page;
    cursor: pointer;
}
.subscriptinput{
  border: 1px solid $input-border;
  outline: none;
  height: 3rem;
  width: 20vw;
  padding-left: 10px;
}


@media screen and (max-width: 799px) {
  .needHelp {
    display: flex;
     flex-direction: column;
      margin-left:20px; 
      margin-bottom:20px;
   }

  .backgrounImg{
     width: 100%;
     justify-content: center;
      display: flex; 
      align-content: center;
      align-self: center;
      align-items: center;
      margin-bottom: 15px,
  }
  .mainPagePaddings{
    padding-top:45px;
    padding-right:20px;
    padding-left:20px 
  }
  .comunityImg{
     width: 100%;
     padding-top: 60px;
    justify-content:center;
    display:flex;
    align-content:center;
    align-self:center; 
    align-items:center;
  }
   .shopNow{
      color:black;
      font-size:25px; 
      padding-top:10px; 
      font-weight: 400px;
      padding-bottom:10px;
      background-color:white;
       width:250px; 
       text-align:center;
    }
    .sellWithus{
      color:black;
       font-size:25px;
      padding-top:10px ;
      font-weight:400;
      padding-bottom:10px; 
    background-color:white;
     width: 250px;
    text-align:center ;
    // margin-top: 20px
    }
    .comunityText{
      color:black;
      font-size:26px; 
      line-height:0.8px,
  }
  .comuntiyDetailText{
    color:#7F1D36;
     font-size:22px;
     font-style:italic;
    padding-top: 20px;
    width: 70%;
    text-align: center;
  }


   }
   
   @media screen and (min-width: 800px) {
    .needHelp {
      display: flex;
       flex-direction: column;
        margin-top: 20px;
        margin-left:20px; 
     }
    .joinNewsLetterBox{
      display: flex;
      padding-bottom: 35; 
      background-color: '#eee'
    }
    .backgrounImg{
      background-repeat: no-repeat;
      background-size: cover;
       height: 80vh;
       width: 100%;
       justify-content: center;
        display: flex; 
        align-content: center;
        align-self: center;
        align-items: center;
        margin-left: 15px,
    }
    .mainPagePaddings{
      padding-top:45px;
    }
    .sellWithus{
      color:black;
       font-size:25px;
      padding-top:10px ;
      font-weight:400;
      padding-bottom:10px; 
    background-color:white;
     width: 250px;
    text-align:center ;
    margin-top: 80px
    }
    .comunityDiv{
      padding-right:90px;
      padding-left:100px; 
      padding-top:20px;
      padding-bottom:45px 
    }
    .comunityImg{
      height:75vh;
       width: 100%;
       padding-top: 60px;
      justify-content:center;
      display:flex;
      align-content:center;
      align-self:center; 
      align-items:center;
    }


  .mainOption{
    padding-right:120px;
     padding-left:85px ;
     display: flex;
  }
    .col-paddings {
padding-right: 0% !important;
padding-left: 0% !important;
    }
    .shopNow{
      color:black;
      font-size:25px; 
      padding-top:10px; 
      font-weight: 400px;
      padding-bottom:10px;
      background-color:white;
       width:250px; 
       text-align:center;
        margin-top:20px
    }
    .comunityText{
      color:black;
      font-size:60px; 
      line-height:0.8px,
  }
  .comuntiyDetailText{
    color:#7F1D36;
     font-size:30px;
     font-style:italic;
    padding-top: 30px
  }
   }