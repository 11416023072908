

 

@media screen and  (max-width: 799px) {
    .font4{
        font-size:18px;
        color:white;
        line-height:0.1;
        font-weight:600
    }
    // color:'white',fontSize:'70px',lineHeight:0.5,fontWeight:600
    .text7{
        font-size:26px;
        color:white;
        font-weight:600;
        text-align: center;
    }
    .text1D{
        font-size:32px;
        color:white;
        line-height:0.7;
        font-weight:600
    }
    .text1{
        font-size:32px;
        color:white;
        line-height:0.6;
        font-weight:600
    }
    .text2{
        font-size:31px;
        color:white;
        line-height:0.5;
        font-weight:600
    }
    .textAccesory{
        font-size:27px;
        color:white;
        line-height:0.5;
        font-weight:600
    }
    .text6{
        font-size:50px;
        color:white;
        line-height:0.9;
        font-weight:600
    }
    .text5{
        font-size:30px;
        color:white;
        line-height:0.9;
        font-weight:600
    }
    .text3{
        color:white;
        font-size:25px;
        line-height:1;
        font-weight:600;
        padding-top: 10px;
    }
    }
@media screen and  (min-width: 799px) {
    .font4{
        font-size:25px;
        color:white;
        line-height:0.2;
        font-weight:600
    }

    .text7{
        font-size:38px;
        color:white;
        font-weight:600;
        text-align: center;
    }

    .text1{
        font-size:50px;
        color:white;
        line-height:1;
        font-weight:600
    }

    .text2{
        font-size:70px;
        color:white;
        line-height:0.5;
        font-weight:600
    }
    .textAccesory{
        font-size:70px;
        color:white;
        line-height:0.5;
        font-weight:600
    }
    .text1D{
        font-size:70px;
        color:white;
        line-height:1;
        font-weight:600
    }
    .text6{
        color:white;
        font-size:95px;
        line-height:1.4;
        font-weight:600,
    }
    .text5{
        color:white;
        font-size:50px;
        line-height:0.4;
        font-weight:600;

    }
    .text3{
        color:white;
        font-size:50px;
        line-height:1;
        font-weight:600;
        padding-top: 10px;
    }
}