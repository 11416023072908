.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
  }
  
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 2px;
    bottom: 4px;
    // right: 15px;
    top: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Select some files';
  display: inline-block;
  background: #f9f9f9;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
::-ms-browse {
    background: black;
    color: red;
    padding: 1em;
  }
  .btn-file {
    position: relative;
    overflow: hidden;
  }
  .btn-file input[type=file] {
    position: absolute;
    // top: 0;
    // right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    // text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
  }
  .head-text {
    position: relative;
 }
 .text-on-image {
   position: absolute;
  //  right: 0%;
   left: 0%;
   bottom: 0%;
 }
 .border-3 {
  border-width:10px !important;
}

.AOlabel{
  color:black;
}


@media screen and (min-width: 800px) {
  
  .AccountOptions{
    display: flex;
  }
  .ImageStl{
    height: 140px;
     width: 140px;
      border-radius: 100;
  }
  .groupImgStl{
    height: 100px;
    width: 100px;
    border-radius: 100
  }
  
 
 
}
@media screen and (max-width: 750px) {

  .AccountOptions{
    display: flex;
    flex-direction: column;
   
  }
  .AOlabel{
  margin: 10;
  }
  .ImageStl{
    height: 120px;
     width: 120px;
      border-radius: 100;
  }
  .groupImgStl{
    height: 90px;
    width: 90px;
    border-radius: 100
  }
  
}
