@import '../../sass/variable';


@media screen and (max-width: 799px) {
    .flexadjustments {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: 800px) {
    .flexadjustments {
        display: flex;
        flex-direction: row;
    }
}