@import './../sass/variable';
.category-item-image{
    height: 25rem;
    width: 100%;
    object-fit: cover;
}
.category-text{
    height: 2rem;
    width: 5rem;
    background-color:'red';
} 
@media screen and (max-width: 799px) {
    .imgSize{
        height:80px;width:80px
    }
    .img-container{
        height: 8rem;
        width: 40vw;
        display: flex;
        margin-bottom: 5px;
        justify-content: center;
        align-items: center;
        background-color: $primary-color;
    }
    .category-image{
        height:20px;
        width: 20px;
    }
}
   
@media screen and (min-width: 800px) {
    .imgSize{
        height:115px;width:115px
    }
    
    .img-container{
        height: 6rem;
        width: 6rem;
        display: flex;
        margin-bottom: 5px;
        justify-content: center;
        align-items: center;
        background-color: $primary-color;
    }
    .category-image{
        height: 6rem;
        width: 6rem;
    } 
}