@import '../../sass/variable';
.store-image{
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    height: inherit;
    width: inherit;
    object-fit: contain;
}
.store-img-container{
    height: 13rem;
    width: 13rem;
    background-color: #f5f5f5;
}