@import "variable";
@import 'mixins';
@font-face {
  font-family: 'Galada-Regular';
  src: local('Galada-Regular'), url(../sass/Galada-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  src: local('Lato-Bold'), url(../sass/Lato-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Mada';
  src: local('Mada-Medium'), url(../sass/Mada-Medium.ttf) format('truetype');
}


/*//////////////////////////////////////////////////////
                   font Families Global
/////////////////////////////////////////////////////*/

.main-font {
  font-family: 'Lato';
}
.sec-font {
  font-family: 'Mada';
}
.italic-font {
  font-family: 'Galada-Regular';
}
.col-md-2{
  padding-right: 4px !important;
  padding-left: 4px !important;
}
.mainHeading{
  font-size: 2rem;
  font-weight:600;
  font-family: $main-font;
}
.secHeading{
  font-size: 1.75rem;
  font-weight:600;
  font-family: $main-font;
}
.labelText{
  font-size: 1rem;
  font-weight:500;
  font-family: $main-font;
}
.hoverUnderLine:hover{
  text-decoration: underline;
}
.labelHeading{
  font-size: 1.1rem;
  font-weight:600;
  font-family: $main-font;
}
.pText{
  font-size: 0.9rem;
  font-weight:300;
  font-family: $main-font;
}

.touchAble {cursor: pointer;}

// .touchAble:hover:after {
//   opacity: 1;
// }
// .touchAble:after {
//   position: absolute;
//   // left: 10px;
//   content: '';
//   // height: 40px;
//   width: 275px;
//   border-bottom: solid 3px #019fb6;
//   transition: left 250ms ease-in-out, right 250ms ease-in-out;
//   opacity: 0;
// }


.sec-font {
  font-family: $sec-font ;
}

.third-font {
  font-family: $third-font;
}


/*//////////////////////////////////////////////////////
                    color Schemes
/////////////////////////////////////////////////////*/

.primary-color-light {
  color: $primary-color-light;
}

.primary-color {
  color: $primary-color !important;
}
.bg-color-secondary {
  background-color: $secondary-color;
}


.text-color {
  color: $text-color;
}


.text-muted {
  color: $text-muted;
}


.page-color{
  color: $page;
}



/*//////////////////////////////////////////////////////
                    Background Color
/////////////////////////////////////////////////////*/

.bg-color-light {
  background-color: $bg-color-light;
}

.bg-color {
  background-color: $bg-color;
}

.bg-color-dark {
  background-color: $bg-color-dark;
}

.bg-color-page {
  background-color: $page;
}

.bg-color-primary {
  background-color: $primary-color;
}


/*//////////////////////////////////////////////////////
                   Text Properties Global
/////////////////////////////////////////////////////*/


.text-center {
  text-align: center;
}


.underline {
  text-decoration-line: underline;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.italic {
  font-style: italic
}

.line-through{
  text-decoration:line-through
}

.pointer{
  cursor: pointer;
}
.link-text:hover{
  cursor: pointer;
  color: $primary-color;
}


/*//////////////////////////////////////////////////////
                    Layout Global
/////////////////////////////////////////////////////*/

.display-flex {
    display: flex;
}

.content-center {
    justify-content: center;
}
  
.content-end {
    justify-content: flex-end;
}
  
.content-start {
    justify-content: flex-start;
}
  
.content-between {
    justify-content: space-between;
}

.wrap-flex {
  flex-wrap: wrap;
}

.col-flex {
  flex-direction: column;
}
  
.row-reverse {
  flex-direction: row-reverse;
}


.align-item-center {
  align-items: center;
}

/*//////////////////////////////////////////////////////
                 Border Global
/////////////////////////////////////////////////////*/

.c-border {
  border: 1px solid $border-color;
}

.border-radius {
  border-radius: $border-radius;
}
.c-border-top{
  border-top: 1px solid $border-color;
}

.c-border-bottom{
  border-bottom: 1px solid $border-color;
}

.c-border-bottom-light{
  border-bottom: 1px solid $border-color-light;
}
.w100p {
  width: 100%
}

/*//////////////////////////////////////////////////////
                    Padding Global
/////////////////////////////////////////////////////*/


.heading {
  padding: $headingPadding;
}

/*//////////////////////////////////////////////////////
                 Global General Stylling
/////////////////////////////////////////////////////*/

@media screen and (max-width: 899px) {
  .paddings{
    padding-left:5;
    padding-right:5;
  }
 .webdisplay{
    display: none !important;
 }
 .page-left-padding {
  padding-left: 1rem;
}

.page-right-padding {
  padding-right: 1rem;
}

}

@media screen and (min-width: 900px) {

  .padding{
    padding-left:10rem;
    padding-right:10rem; 
  }
  .paddings{
    padding-left:7.5rem;
    padding-right:7.5rem; 
  }

  .mobiledisplay{
    display: none !important;
  }
  .page-left-padding {
    padding-left: $horizontal-space;
  }
  
  .page-right-padding {
    padding-right: $horizontal-space;
  }
  
}
.div-box-shadow{
  box-shadow: $div-box-shadow;
}
.position-relative{
  position: relative;
}

.position-absolute{
  position: absolute;
}


