
@import '../../sass/variable';
input {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid black;
  }
  .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: #732F3E !important;
}


@media screen and (max-width: 799px) {
  .margintop{
    margin-top:5%;
  }
  .image-height{
    width: 80vw;
    height: 20vh;
  }
 }
 
 @media screen and (min-width: 800px) {

  .margintop{
    margin-top:2%;
  }
  .image-height{
    width: 25vw;
    height: 5vh;
  }
 }

