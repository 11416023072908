@import './../sass/variable';

.product-img-container{
    height: 13rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#f5f5f5;
}
.product-image{
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    height: inherit;
    width: inherit;
    object-fit: cover;
}

.bundle-contianer{
    position: absolute !important;
    left: 0 !important ;
    top: 0 !important;
}
@media screen and (max-width: 799px) {
    .off-contianer{
        position: absolute !important;
        right: 23;
        top: 0;
        border-bottom-right-radius: $border-radius;
    }
    .off-contianerInReview{
        position: absolute !important;
        border-bottom-right-radius: $border-radius;
    }
}
   
@media screen and (min-width: 800px) {
    .off-contianer{
        position: absolute !important;
        right: 37;
        top: 0;
        border-bottom-right-radius: $border-radius;
    }
    .off-contianerInReview{
        position: absolute !important;
        border-bottom-right-radius: $border-radius;
    }
}