.inputsearch {
    border-bottom: 0ch !important;
      }
      .col-md-4{
        padding-right: 4px !important;
        padding-left: 4px !important;
      }
      .paddingss {
        padding-right: 13px !important;
        padding-left: 13px !important;
        padding-top: 0px !important;
        // margin-left: 10px !important;
      }
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(226, 223, 223); 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(233, 120, 139)
  };
 
  @media screen and (min-width: 800px) {
    .webPaddings {
      padding-right: 25px;
   }
   .paddingsMobile {
    padding-right: 0px !important;
    padding-left: 22px !important;
    padding-top: 22px !important;
  }
   }
  @media screen and (max-width: 799px) {
    
   .paddingsMobile {
    padding-right: 0px !important;
    padding-left: 12px !important;
    padding-top: 12px !important;
  }
   }
 