@import '../../sass/variable';


.paddingOFmd3{
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
.about-Seller-img{
    height: 6rem;
    width: 6rem;
    object-fit: cover;
    border-radius: 50%;
}

@media screen and (max-width: 799px) {
    .paddingsMobile {
        padding-right: 5px !important;
        padding-left: 5px !important;
        padding-top: 10px !important;
        // margin-left: 10px !important;
      }
    .product{
        display: flex;
        flex-direction:column;
    }
    .mobilePaddings{
       padding-left: 15px;
       padding-right: 15px;
    }

    .productcarosal{
columns: 12;
display: flex;
flex-direction: row;
    }
    .carosal-image{
        width: 70vw;
        height: 45vh;
        object-fit: cover;
    }
    .mobilecarosal{
       display: flex;
       justify-content: center;

    }
    .productDetail{
        flex-direction: row;
        display: flex;
                    }
                    .productdetailcolumn{
                       display: flex;
                       flex-direction: column;
                       justify-content: center;
                       text-align: center;
                    }
                    .Venderdescription{
                        width: 80vw;
                    }
               
   }
   
   
   @media screen and (min-width: 800px) {
   
    .product{
        display: flex;
        flex-direction:row;
    }
          .carosal{
justify-content: center;
display: flex;
box-shadow: inset;
            }
            .productDetail{
columns: 5;
            }
            .productdetailcolumn{
                display: flex;
                flex-direction: column;
            columns: 12;
            
             }
             .Venderdescription{
                width: 40vw;
            }
            .carosal-image-Web{
                object-fit: cover;
            }

   }
