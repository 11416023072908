
@import './../sass/variable';

html {
    scroll-behavior: smooth;
  }
@media screen and (max-width: 799px) {
    .stepers{
        display: flex;
        flex-direction: column;
       align-items: center;
       height: auto;
       width: auto;
    }
    .headingShort{
        padding-top: 7%;
            }
    .steperImg{
        display: flex;
        flex-direction: column;
       align-items: center;
    }
 
    .FollowUp{
        display: flex;
        flex-direction: column;
       align-items: center;
    }
    .paddingLeft{
        padding-left: 0px !important;
        display: flex;
        flex-direction: column;
    }
    
    .sidepadding{
        padding-left: 20px ;
        padding-right: 20px ;
    }
    .sidepaddingwhatSell{
        padding-left: 20px ;
        padding-right: 20px ;
    }
    
}
@media screen and (min-width:800px) {
    .stepers{
        display: flex;
        justify-content: center;
    }
    .headingShort{
        padding-top: 3.5%;
            }
 
    .FollowUp{
        display: flex;
        justify-content: center;
    }
    .paddingLeft{
        padding-left: 0px !important;
        display: flex;
        flex-direction: row;
    }
    .sidepadding{
        padding-left: 50px ;
        padding-right: 50px ;

    }
    .sidepaddingwhatSell{
        padding-left: 25% ;
        padding-right: 25% ;

    }
}