.c-searchfield{
border: none;
outline: none;
}
.textColor{
    color:white;
}
.background{
    background-color: #383838; 
    background-image: #383838
}
.Social-links{
height: 4vh;
width: 4vh;
border-radius: 100%;
}
.cardsss{
height: 6vh;
width: auto;

}


@media screen and ( max-width:799px) {
    .mobileFooter{
        display:flex;
        align-items:center;
        flex-direction:column;
    }
}
@media screen and ( min-width:800px) {
    .mobileFooter{
        padding-left: 95px;
        }
}