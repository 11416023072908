p{
    font-size: calc(1em + 0vmin);
}

.f-12{
    font-size: calc(.75em + .1vmin);
}
.f-13{
    font-size: calc(.75em + .275vmin);
}
.f-14{
    font-size: calc(.75em + .45vmin);
}
.f-15{
    font-size: calc(.75em + .625vmin);
}
.f-16{
    font-size: calc(.75em + .8vmin);
}
.f-17{
    font-size: calc(.75em + .975vmin);
}
.f-18{
    font-size: calc(.75em + 1.15vmin);
}
.f-19{
    font-size: calc(.75em + 1.325vmin);
}
.f-20{
    font-size: calc(.75em + 1.5vmin);
}

.f-22{
    font-size: calc(.75em + 1.850vmin);
}
.f-23{
    font-size: calc(.75em + 2.025vmin);
}
.f-25{
    font-size: calc(.75em + 2.375vmin);
}
.f-30{
    font-size: calc(.75em + 3.25vmin);
}


.f-200{
    font-weight: 200;
}

.f-500{
    font-weight: 500;
}

.f-600{
    font-weight: 600;
}

.f-700{
    font-weight: 700;
}


