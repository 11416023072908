
@import '../../sass/variable';

 .paddinssg{
     margin-left: 10%;
     margin-right: 10%;
 }
 .cards{
  height: 8vh;
  width: 12vh;
  }
  
 
@media screen and (max-width: 799px) {
  .mobileView {
display: flex;
flex-direction:column; 
 }
 .mobileColumn {
  display: flex;
  flex-direction:column; 
  padding-top: 15px;
   }

  .margintop{
    margin-top:5%;
  }
  .image-height{
    width: 80vw;
    height: 20vh;
  }
  .download-image-styling{
    width: 100%;
    height: 23vh;
    object-fit: cover;
}
  
 }
 
 @media screen and (min-width: 800px) {
  .mobileColumn {
    display: flex;
    flex-direction:row; 
     }
  .mobileView {
    display: flex;
    flex-direction:row; 
    padding-left: 1rem;
    padding-right: 1rem;
     }

  .margintop{
    margin-top:2%;
  }
  .image-height{
    width: 25vw;
    height: 5vh;
  }
  .download-image-styling{
    width: 100%;
    height: 55vh;
    object-fit: cover;
}
 }

