$spaceamounts: (0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,
51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,);
$sides: (top, bottom, left, right);
$units: (rem,vh,vw);
@each $space in $spaceamounts {
    @each $side in $sides {
        // margin & padding in Pixel Unit by default just Type .m-side-amount or m-amount unit will be px

        .cm-#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}px;
        }
     
        .cp-#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space}px;
        }

        .cp-#{$space}{
            padding: #{$space}px;
        }
     
        .cm-#{$space}{
            margin: #{$space}px;
        }

        //height & Width in pixel Unit by default

        .ch-#{$space}{
            height: #{$space}px;
        }
        .cw-#{$space}{
            width: #{$space}px;
        }

        @each $unit in $units {

             // margin & padding in rem,vh,vw , Type .m-side-amountunit or m-amountunit
       
            .cm-#{str-slice($side, 0, 1)}-#{$space}#{$unit} {
                margin-#{$side}: #{$space}#{$unit};
            }
  
            .cp-#{str-slice($side, 0, 1)}-#{$space}#{$unit} {
                padding-#{$side}: #{$space}#{$unit};
            }
            
            .cp-#{$space}#{$unit}{
                padding: #{$space}#{$unit};
            }
            
            .cm-#{$space}#{$unit}{
                margin: #{$space}#{$unit};
            } 

            //height & Width in rem,vh,vw , Type .h-amountunit or h-amountunit

            .ch-#{$space}#{$unit}{
                height: #{$space}#{$unit};
            }

            .cw-#{$space}#{$unit}{
                width: #{$space}#{$unit};
            }
        }
    }



}


@mixin transparentScrollBar {
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  