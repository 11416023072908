@import'./../sass/variable';

.col{
padding-right: 0% !important;
padding-left: 0% !important;
}


.c-searchfield{
border: none;
outline: none;
}
.border-danger{
    border-bottom-color: #d05F73;
    border-bottom-style: solid;
}
.z-index-9{
    z-index: 9;
}
.link22 {
    color:red;
  }
  .a:hover {
     color: red
  }
  .hoverColor:hover{
      color:#d05F73;
      text-decoration: underline;
  }

@media screen and (max-width: 799px) {
    .c-searchfield{
        border: none;
        outline: none;
        width: 40vw;
    }
    .freeSHipingText{
        font-size: 10px;
    }
}
@media screen and (min-width: 800px) {
    .c-searchfield{
        border: none;
        outline: none;
        width: 40vw;
    }
    .freeSHipingText{
        font-size: 15;
    }
}