@import './../sass/variable';

.containesssr {
    position: relative;
  }
  
  .topleft {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 18px;
  }
  

  .bottomright {
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 18px;
    margin-top:10;
  }


.product-img-container{
    height: 13rem;
    width: 100%;
    display: flex;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
}
.product-image{
    height: inherit;
    width: inherit;
    object-fit: cover;
}
.off-contianer{
    position: absolute !important;
    right: 0;
    top: 11rem;
    border-bottom-right-radius: $border-radius;

}
.bundle-contianer{
    position: absolute !important;
    left: 0;
    top: 0;
    border-top-left-radius: $border-radius;

}