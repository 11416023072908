$main-font:"MADE+TOMMY", sans-serif;
$sec-font :'Roboto', sans-serif ;
$third-font:'Roboto', sans-serif ;

$primary-color: #732F3E;
$primary-color-light:rgb(224, 169, 179);  //400 from plate
$error: red;
$input-border:gray;
$input-bg:transparent;
$input-height:2.5rem;
$input-horizontal-space:.7rem;
$input-focus-shadow: 0px 0px 4px 0px $primary-color;
$input-focus-shadow-error: 0px 0px 4px 0px $error;



$page:white ;
$border-color:gray;
$border-color-light:rgb(187, 186, 186);

$text-color:black ;
$text-muted:rgb(46, 45, 45) ;


$bg-color-light:#f0f2f5 ;
$secondary-color: rgb(10, 10, 10);
$bg-color:#c5daee ;
$bg-color-dark:#9ec2e3;

$border-radius:5px;
$horizontal-space: 3rem;
$vertical-space: 3rem;
$headingPadding:2rem;

$div-box-shadow:4px 3px 9px 5px #80808033;
$div-hover-shadow:1px 2px 2px 3px #80808033;
