 @import '../../sass/variable';

 .largerCheckbox {
   width: 20px;
   height: 20px;
 }

 .btn-primary {
   border: 0 !important;
 }

 .dddddd {
   width: 50%;
   height: 38px;
   border-radius: 50 !important;
   border-width: 1px !important;
   border: 0 !important;
   border: solid;
 }

 .calendercol {
   height: 70;
 }

 .label {
   margin-bottom: 0 !important;
 }

 .margiLR {
   margin-left: 0 !important;
   margin-right: 0 !important;
 }

 @media screen and (max-width: 799px) {
   .margin {
     margin: 3%;
   }

   .marginLR {
     margin-left: 9%;
     margin-right: 9%;
   }
 }

 @media screen and (min-width: 800px) {
   .marginLR {
     margin-left: 5%;
     margin-right: 5%;
   }
 }